<template>
    <div class="system-container">
        <el-row>
            <el-button type="primary" size="small" @click="addUserInfo">添加</el-button>
            <el-button type="primary" size="small" @click="userListExport" plain>导出</el-button>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-input v-model="searchValue" style="width: 200px;float:right;" placeholder="请输入用户名或账号或名称进行搜索" @keyup.enter.native="initPage(1)">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="initPage(1)" style="cursor: pointer;"></i>
            </el-input>
        </el-row>
        <el-row style="margin-top:10px;">
            <el-table
                :data="userList"
                border
                style="width: 100%"
                :row-style="{height:'20px'}"
                :cell-style="{padding:'2px'}">
                <el-table-column
                    align="center"
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="editUser(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="userName"
                    label="用户名"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="loginName"
                    label="登录名/账号"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="empNo"
                    label="工号"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="电话"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="mail"
                    label="E-Mail"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="dept"
                    label="部门"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="position"
                    label="岗位"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="leader"
                    label="直接领导"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="createDate"
                    label="创建日期"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="reportNo"
                    label="报告次数"
                    align="center"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    label="状态"
                    align="center"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-dropdown  trigger="click" @command="changeUserStatus">
                            <span class="el-dropdown-link1">
                                {{scope.row.statusName}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in statusList" :key="item.status" :command="composeValue(item,scope.row)">{{item.statusName}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row style="text-align:right; margin-top:30px;">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="turningPage"
                :total="pageTotal">
            </el-pagination>
        </el-row>
        <UserInfoDialog :isShowUserInfoDialogg="isShowUserInfoDialog" @update-user="updataReset"></UserInfoDialog>
    </div>
</template>

<script>
import api from "@/api/index.js";
import UserInfoDialog from "@/components/Dialog/Info/UserInfoDialog.vue";
import constants from "@/constants/Map.constants.js";
const gap = 100;
export default {
    name: "userMaintain",
    data(){
        return {
            bufferList:[],
            model: "",
            options: {
                page: 1,
                size: 10,
                parameter: "",
                cusNo: ""
            },
            userList: [],
            pageTotal: 0,
            searchValue: "",
            isShowUserInfoDialog: false,
            statusList: [
                {
                    status: 0,
                    statusName: "禁用"
                },
                {
                    status: 1,
                    statusName: "可用"
                },
                // {
                //     status: 2,
                //     statusName: "未激活"
                // }
            ]
        }
    },
    components: {
        UserInfoDialog
    },
    mounted(){
        this.options.cusNo = sessionStorage.cusNo;
        this.initPage();
    },
    methods: {
        initPage(page = 1) {
            this.options.page = page;
            this.options.parameter = this.searchValue;
            this.userList = [];
            api.userList(this.options).then(res => {
                if(res.code == 200 && res.data.rows.length > 0) {
                    res.data.rows.forEach(item => {
                        if(item.isAdmin==null) {
                            item.isAdmin = 0
                        }
                        this.userList.push(item);
                    });
                    this.pageTotal = res.data.total;
                } else {
                    this.userList = [];
                    this.pageTotal = 0;
                }
            });
        },
        addUserInfo() {
            this.isShowUserInfoDialog = true;
        },
        editUser(val) {
            this.isShowUserInfoDialog = true;
            this.$store.state.userInfo = val;
        },
        updataReset(val){
            this.isShowUserInfoDialog = val;
            this.initPage();
        },
        //状态修改携带参数修改
        composeValue(command, row) {
            return {
                command,
                row
            };
        },
        changeUserStatus(val) {
            console.log(val);
            let command = val.command;
            let row = val.row;
            if(command.status != row.status) {
                this.$confirm('', `确认${command.statusName}该用户`, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                        //确认退出，修改缓存
                        row.cusNo = this.cusNo;
                        row.status = command.status;
                        row.statusName = command.statusName;
                        api.changeUserStatus(row).then(res => {
                            this.$message({
                                message: `${row.userName}用户状态修改成功`,
                                type: "success"
                            })
                        });
                    }).catch(() => {
                    //取消  
                });
            }
            
        },
        turningPage(page) {
            this.initPage(page);
        },
        userListExport(){
            api.userListExport(this.options).then(res => {
                console.log(res);
                if(res.code == 200 && res.data != null) {
                    location.href = constants.baseURL.API + res.data;
                }
            });
        }
    }
}
</script>
<style>
.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.system-text {
    font-size: 13px;
}
.el-dropdown-link1 {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .system-container .number {
      float: left;
  }
  .system-container .more {
      float: left;
  }
</style>
