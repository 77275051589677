<template>
    <el-dialog :visible.sync="visible" width="30%" :show-close="true" class="register-dialog resetPsd-dialog" 
        center  :before-close="closeResetPsd">
        <span slot="title">{{titleName}}</span>
        <el-divider></el-divider>
        <div>
            <el-form :model="userInfoForm" :rules="userInfoRules" ref="userInfoForm" label-width="90px" size="mini">
                <el-form-item label="用户名" prop="userName" class="userinfo-label">
                    <el-input class="register-input" v-model="userInfoForm.userName" size="small"></el-input>
                </el-form-item>
                <el-form-item label="登录名/账号" prop="loginName" class="userinfo-label">
                    <el-input class="register-input" v-model="userInfoForm.loginName" size="small"></el-input>
                </el-form-item>
                <el-form-item label="工号" prop="empNo" class="userinfo-label">
                    <el-input class="register-input" v-model="userInfoForm.empNo" size="small"></el-input>
                </el-form-item>
                <el-form-item label="初始密码" prop="pwd" class="userinfo-label">
                    <el-input type="password" class="register-input" v-model="userInfoForm.pwd" size="small"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPsd" class="userinfo-label">
                    <el-input type="password" class="register-input" v-model="userInfoForm.confirmPsd" size="small"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone" class="userinfo-label">
                    <el-input class="register-input" v-model="userInfoForm.phone" size="small"></el-input>
                </el-form-item>
                <el-form-item label="E-Mail" prop="mail" class="userinfo-label">
                    <el-input class="register-input" v-model="userInfoForm.mail" size="small"></el-input>
                </el-form-item>
                <el-form-item label="直接领导" prop="leader" class="userinfo-label">
                    <el-input class="register-input" v-model="userInfoForm.leader" size="small"></el-input>
                </el-form-item>
                <el-form-item label="部门" prop="dept" class="userinfo-label">
                    <el-input class="register-input" v-model="userInfoForm.dept" size="small"></el-input>
                </el-form-item>
                <el-form-item label="岗位" prop="position" class="userinfo-label">
                    <el-input class="register-input" v-model="userInfoForm.position" size="small"></el-input>
                </el-form-item>
                <el-form-item label="管理员" prop="isAdmin" class="userinfo-label">
                    <!-- <el-input class="register-input" v-model="userInfoForm.statusName" size="small"></el-input> -->
                    <el-radio v-model="userInfoForm.isAdmin" :label="1"><span style="font-size: 12px;">是</span></el-radio>
                    <el-radio v-model="userInfoForm.isAdmin" :label="0"><span style="font-size: 12px;">否</span></el-radio>
                </el-form-item>
                <el-form-item label="状态" prop="status" class="userinfo-label">
                    <!-- <el-input class="register-input" v-model="userInfoForm.statusName" size="small"></el-input> -->
                    <el-radio v-model="userInfoForm.status" :label="1"><span style="font-size: 12px;">可用</span></el-radio>
                    <el-radio v-model="userInfoForm.status" :label="0"><span style="font-size: 12px;">禁用</span></el-radio>
                </el-form-item>
            </el-form>
            <el-row style="text-align:center">
                <el-button style="width: 40%;margin-right:20px;" @click="closeResetPsd">取消</el-button>
                <el-button type="primary" style="width: 40%" @click="confirmResetPsd('userInfoForm')">保存</el-button>
            </el-row>
        </div>
    </el-dialog>
</template>
<script>
import api from "@/api/index.js";
import md5 from 'js-md5';
export default {
    name: "UserInfoDialog",
    props: ["isShowUserInfoDialogg"],
    data(){
        var that = this;
        //验证新密码是否符合要求
        var checkNewPsd = (rule, value, callback) => {
            if(!!value) {
                if (value.length<6||value.length>16) {
                    callback(new Error('6-16位字符组成，区分大小写'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        //验证两次密码是否一致
        var validatePass = (rule, value, callback) => {
            if(!!that.userInfoForm.pwd) {
                if (value === '') {
                    callback(new Error('请确认密码'));
                } else if (value !== this.userInfoForm.pwd) {
                    callback(new Error('两次输入密码不一致'));
                } else {
                    callback();
                }
            }else {
                callback();
            }
        };
        return {
            visible: false,
            userInfoForm: {
                userName: "",
                loginName: "",
                phone: "",
                mail: "",
                leader: "",
                dept: "",
                position: "",
                status: "",
                pwd: "",
                statusName: "",
                confirmPsd: "",
                empNo: "",
                isAdmin: 0
            },
            userInfoRules: {
                pwd: [{validator: checkNewPsd, trigger: 'blur' }],
                confirmPsd: [{validator: validatePass, trigger: 'blur' }]
            },
            titleName: ""
        }
    },
    mounted(){
        this.visible = this.isShowUserInfoDialogg;
        this.resetPsdForm = {
            oldPsd: "",
            newPsd: "",
            confirmPsd: ""
        };
    },
    methods:{
        confirmResetPsd(formName){
            var that = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    that.userInfoForm.cusNo = sessionStorage.cusNo;
                    that.userInfoForm.createDate = that.$Format();
                    that.userInfoForm.pwd = !!that.userInfoForm.pwd?md5(that.userInfoForm.pwd):"";
                    that.userInfoForm.confirmPsd = !!that.userInfoForm.confirmPsd?md5(that.userInfoForm.confirmPsd):"";
                    api.addUser(that.userInfoForm).then(res => {
                        if(res.code == 200) {
                            that.$message({
                                message: res.message,
                                type: "success"
                            });
                            that.visible = false;
                            that.$emit("update-user",this.visible);
                        }
                        else {
                            that.$message.error(res.message);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    that.$message({
                        message: 'error submit!!',
                        type: "warning"
                    });
                    return false;
                }
            });
        },
        closeResetPsd(){
            this.visible = false;
            this.$emit("update-user",this.visible);
            this.userInfo = {};
            this.titleName = "";
            this.$store.state.userInfo = {};
        }
    },
    watch:{
        isShowUserInfoDialogg(val){
            this.visible = val;
            if(this.visible) {
                if(Object.keys(this.$store.state.userInfo).length == 0) {
                    this.userInfoForm = {
                        userName: "",
                        loginName: "",
                        phone: "",
                        mail: "",
                        leader: "",
                        dept: "",
                        position: "",
                        status: 1,
                        statusName: "可用",
                        isAdmin: 0
                    };
                    this.titleName = "新增用户";
                } else {
                    this.userInfoForm = this.$store.state.userInfo;
                    this.titleName = "编辑用户";
                }
            } else {
                this.userInfo = {};
            }
        }
    }
}
</script>
<style>
.resetPsd-dialog .el-divider--horizontal{
    margin-top: 10px;
    margin-left: -25px;
    padding-left: 50px;
}
.userinfo-label.el-form-item--mini.el-form-item {
    margin-bottom: 12px;
}
.userinfo-label .el-form-item__label {
    font-size: 12px;
}
</style>